const translations = Object.assign(
  {},
  require("../../node_modules/front-commerce/translations/fr.json"),
  require("../../node_modules/front-commerce/modules/shipping-colissimo-magento2/translations/fr.json"),
  require("../../node_modules/front-commerce/modules/shipping-mondialrelay/translations/fr.json"),
  require("../../node_modules/front-commerce/theme-chocolatine/translations/fr.json"),
  require("../../node_modules/front-commerce/modules/auth-external-login/translations/fr.json"),
  require("../../node_modules/front-commerce/modules/payment-adyen/translations/fr.json"),
  require("../../src/translations/fr.json"),
  require("../../translations/fr-FR.json")
);
module.exports = translations;
